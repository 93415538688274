.wrapper{
    width: 100%;
    background-color: transparent;
    margin-left: 4px;
    margin-top: 1px;
    padding-left: 4px;
}
.ant-input{
    border-style: none;
}
.ant-input:focus{
    border-style: none;
    box-shadow: none;
}
