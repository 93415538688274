.ant-btn-sm{
    background-color: #EA5B13;
    border-radius: 20px;
    border-style: none;
}
.ant-btn-sm:hover{
    background-color: #EA5B13;
}

.ant-picker-input>input{
    font-family: Nunito;
    font-size: 20px;
    font-weight: 400;
}

input{
    text-align: center;
}

.ant-message-notice{
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-message-notice-content{
    height: 100px;
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ant-message-custom-content{
    font-family: Nunito;
    font-size: 20px;
    font-weight: 600;
}
