.custom-input-wrapper{
    border-radius: 30px;
    height: 40px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    border-style: none;
    display: flex;
    align-items: center;
    border-color: #d9d9d9;
    border-width: 1px;
    text-align: left !important;
}
.custom-input-wrapper:hover{
    border-style: solid;
    border-width: 1px;
    border-color: #673178;
}

.ant-form-item-explain-error{
    font-family: Nunito;
    font-size: 16px;
    padding-left: 16px;
}

.ant-pagination{
    display: flex;
    height: 60px;
    place-content: center;
    flex-direction: row;
    align-items: baseline;
}

.ant-pagination-item{
    border-color: #EA5B13 !important;
    border-width: 1px;
    border-radius: 35px;
    height: 32px  !important;
    width: 30px  !important;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 3px !important;
}

.ant-pagination-item a{
    color: #EA5B13  !important;
}

.ant-pagination-item-active{
    border-width: 0;
    background-color: #EA5B13;
    border-radius: 40px;
    height: 45px !important;
    width: 45px !important;
    justify-content: center;
    align-items: center;
    display: flex;
}

.ant-pagination-item-active a{
    color: #fff !important;
}

.ant-modal-content{
    border-radius: 10px;
}

.ant-modal{
    border-radius: 10px;
}

.ant-modal-header{
    border-radius: 10px;
}

.ant-modal-header{
    border-width: 0;
}

.ant-modal-footer{
    border-width: 0;
}

.ant-input{
    text-align: left !important;
    padding-left: 20px;
}

.ant-select-dropdown-menu {
    text-align: left !important;
}

.ant-select-selection-search-input{
    text-align: left !important;
}

.ant-picker-input .input{
    text-align: left !important;
}
.ant-input[disabled]{
    background-color: transparent;
}
