.fade {
    animation: fade-in-keyframes 0.5s;
}
@keyframes fade-in-keyframes {
    from {opacity: 0}
    to {opacity: 1}
}
button {
    outline: none;
    border: none;
}
.button:hover{
cursor: pointer;
}
