.ant-radio-inner {
    width: 29px;
    height: 29px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-style: none;
}

.ant-radio-inner::after {
    width: 30px;
    height: 30px;
    margin: 0;
    position: absolute;
    border-radius: 30px;
    top: -0.1px;
    left: -0.2px;
}

.ant-radio-checked .ant-radio-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.ant-radio-wrapper {
    align-items: center;
}

.ant-radio {
    top: 0;
}
